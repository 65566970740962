/* global config, Promise, angular */

'use strict';

angular.module('managerApp').factory('ads', function (http, toast) {
    const url = config.apiURL + 'ads/';
    function errorHandler(err) {
        if (err) {
            if (err.message) {
                if (err.type !== 'HTTPService') {
                    return Promise.reject(err.message);
                } else {
                    toast.show(err.message, 'error', true);
                }
            } else {
                if (err.data && err.data.message) {
                    return Promise.reject(err.data);
                }
            }
            return Promise.reject(err);
        }
        return Promise.reject();
    }
    return {
        get: (id = null) => {
            let _url = url;
            if(id !== null){
                _url += id;
            }
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    resolve(res.data);
                }).catch(errorHandler);
            });
        },
        add: (item) => {
            return http.post(url, item, {
                headers: {'Content-Type': undefined}
            }).then((res) => {
                return Promise.resolve(res);
            }).catch(errorHandler);
        },
        update: (item, id) => {
            return http.put(url + id, item, {
                headers: {'Content-Type': undefined}
            }).then((res) => {
                return Promise.resolve(res);
            }).catch(errorHandler);
        },
        delete: (item) => {            
            return http.delete(url + (item._id ? item._id : item)).then((res) => {
                return Promise.resolve(res);
            }).catch(errorHandler);
        }
    };
});